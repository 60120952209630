import type { ErrorResponse, ValidationError } from "@/types/error";

export function errorMessageExtractor(error: ErrorResponse) {
  let errorMessage = "";
  let validationErrors: ValidationError[] = [];

  if (error.response?.data) {
    if (error.response.data.error) {
      errorMessage = error.response.data.error;
    }

    if (error.response.data.errors) {
      validationErrors = error.response.data.errors;
    }
  } else if (error?.message) {
    errorMessage = error.message;
  } else {
    errorMessage = "Something went wrong";
  }

  return {
    message: errorMessage,
    errors: validationErrors,
  };
}
